<template>
  <div>
    <v-row
      justify="center"
      justify-md="end"
    >
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modalS"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="start"
              label="Fecha de inicio"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="start"
            color="light-blue darken-1"
            scrollable
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalS = false"
            >
              <!-- @click="$refs.dialog.save(start)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modalE"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="end"
              label="Fecha final"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="end"
            scrollable
            color="light-blue darken-1"
            locale="es-ve"
          >
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modalE = false"
            >
              <!-- @click="$refs.dialog.save(end)" -->
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="typeTransactionId"
          label="Tipo de transacción"
          :loading="fetchingTypeTransaction"
          :disabled="fetchingTypeTransaction"
          :items="getTypeTransaction"
          item-text="name"
          item-value="typeTransactionId"
          no-data-text="No hay coincidencia"
        />
      </v-col> -->
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="warehouseId"
          label="Almacén"
          :loading="fetchingWarehouses"
          :disabled="fetchingWarehouses"
          :items="getWarehouses"
          item-text="name"
          item-value="warehouseId"
          no-data-text="No hay coincidencia"
        />
      </v-col>
      <!-- <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="relatedWarehouseId"
          label="Almacén de relación"
          :loading="fetchingStoreWarehouses"
          :disabled="fetchingStoreWarehouses"
          :items="getStoreWarehouses"
          item-text="business"
          item-value="warehouseId"
          no-data-text="No hay coincidencia"
        />
      </v-col> -->
      <!-- <v-col
        cols="12"
        sm="6"
        md="4"
      >
        <v-autocomplete
          v-model="approved"
          label="Solicitudes"
          :items="status"
          no-data-text="No hay coincidencia"
        />
      </v-col> -->
      <v-col cols="2" />
    </v-row>

    <add-concessions v-model="openedAddDialog" />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex'
  import AddConcessions from './AddConcessions.vue'
  import { DateTime } from 'luxon'
  import { TYPE_TRANSACTION } from '../../../../const'

  export default {
    name: 'ReportsTableTop',

    components: {
      AddConcessions,
    },

    data () {
      return {
        warehouseId: '',
        start: '',
        end: '',
        relatedWarehouseId: '',
        approved: 'TODAS',
        modalS: false,
        modalE: false,
        openedAddDialog: false,
        status: ['TODAS', 'APROBADAS', 'SIN APROBAR'],
        initialData: true,
        typeTransaction: [],
        fetchingTypeTransaction: false,
        typeTransactionId: '',
      }
    },

    computed: {
      ...mapState([
        'fetchingStoreWarehouses',
        'storeWarehouses',
        'actionWarehouse',
        'fetchingReports',
        'reports',
        'warehouses',
        'fetchingWarehouses',
      ]),

      getStoreWarehouses () {
        return [{ business: 'TODAS', warehouseId: '' }, ...this.storeWarehouses]
      },

      getWarehouses () {
        return [{ name: 'TODOS', warehouseId: '' }, ...this.warehouses]
      },

      getTypeTransaction () {
        return [{ name: 'TODOS', typeTransactionId: '' }, ...this.typeTransaction]
      },

      form () {
        return {
          warehouseId: this.warehouseId,
          typeTransactionId: this.typeTransactionId,
          start: this.start,
          end: this.end,
          relatedWarehouseId: this.relatedWarehouseId,
          approved: this.approved === 'TODAS' ? '' : this.approved === 'APROBADAS' ? 1 : 0,
        }
      },
    },

    watch: {
      async form (v) {
        if (!this.initialData) {
          await this.fetchReportsCurrency(v)
        }
      },
    },

    created () {
      this.end = DateTime.fromJSDate(this.nextWeek()).toFormat('yyyy-MM-dd')
      this.start = DateTime.fromJSDate(this.lastWeek()).toFormat('yyyy-MM-dd')
      this.initialData = false
      this.fetchingTypeTransaction = true
      for (const [key, typeTransactionId] of Object.entries(TYPE_TRANSACTION)) {
        this.typeTransaction.push({ typeTransactionId, name: key })
      }
      this.fetchingTypeTransaction = false
    },

    methods: {
      ...mapActions([
        'fetchWarehouseConcessions',
        'fetchReports',
        'fetchReportsCurrency',
      ]),

      ...mapMutations({
        changeParams: 'CHANGE_PARAMS',
      }),

      lastWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
      },

      nextWeek () {
        const today = new Date()
        return new Date(today.getFullYear(), today.getMonth(), today.getDate() + 7)
      },
    },

  }
</script>
