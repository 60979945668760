<template>
  <v-container
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-certificate-outline"
          title="Reporte por monedas"
          class="px-5 py-3"
          color="orange"
        >
          <reports-table-top />
          <v-container>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Busca por cualquier campo"
              single-line
              hide-details
            />
          </v-container>
          <v-data-table
            :headers="headers"
            :items="reportsCurrency"
            :loading="fetchingReportsCurrency"
            :search="search"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          />
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import ReportsTableTop from './ReportsTableTop'
  // import ConcessionsActions from './ConcessionsActions.vue'

  export default {
    name: 'ReportsTable',
    components: {
      ReportsTableTop,
      // ConcessionsActions,
    },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'Moneda',
            value: 'name',
          },
          {
            text: 'Símbolo',
            value: 'symbol',
          },
          {
            text: 'Total',
            value: 'totalAmount',
          },
        ],
      }
    },
    computed: {
      ...mapState([
        'fetchingWarehouseConcessions',
        'warehousesConcessions',
        'fetchingReports',
        'reports',
        'reportsCurrency',
        'fetchingReportsCurrency',
        // 'products',
        // 'fetchingProducts',
      ]),
    },
  }
</script>
