<template>
  <reports-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import ReportsTable from '../components/reports-currency/ReportsTable.vue'
  export default {
    name: 'ReportsCurrency',
    components: {
      ReportsTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      await this.getStoreWarehouses()
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchReportsCurrency()
    },

    methods: {
      ...mapActions([
        'getStoreWarehouses',
        'fetchProducts',
        'getWarehousesByParentId',
        'fetchReportsCurrency',
      ]),
    },
  }
</script>
